import React from "react";
import ConfigService from "../../Services/ConfigService";

function FooterComponent(): JSX.Element {
  const [year, setYear] = React.useState<number>(new Date().getFullYear())
  const appVersion = ConfigService.getAppVersion()


  return (
    <footer className="main-footer d-flex d-sm-block flex-column-reverse">
      <div className="float-right d-sm-inline">
        Version: {appVersion}
      </div>

      <div
        style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
      >
        <strong>Copyright &copy; {year} Rosenbergquelle Betriebsges.m.b.H.</strong> All rights reserved.
      </div>
    </footer>
  )
}

export default FooterComponent;