import React, {ReactNode, useState} from "react";
import Modal from "react-bootstrap/Modal";


interface Props {
  title: string | ReactNode,
  onClose: () => void,
  children: ReactNode,
}

export default function BaseModal(props: Props) {
  const [variable, set_variable] = useState(false)

  return (
    <Modal
      show={true}
      size={"lg"}
      onEscapeKeyDown={() => props.onClose()}
    >
      { (typeof(props.title) === "string") &&
        <Modal.Header>
          <h3>{props.title}</h3>
        </Modal.Header>
      }
      { (typeof(props.title) !== "string") &&
        <Modal.Header>
          {props.title}
        </Modal.Header>
      }

      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  )

}

const style: {
  // view: CSSProperties
} = {
  // view: {}
}
