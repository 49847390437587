import TableComponent, {TableColumn} from "./TableComponent";
import FloatService from "../../Services/FloatService";
import BaseVMComponent from "../BaseVMComponent";
import AllContainersAtClientsViewModel from "../../ViewModels/AllContainersAtClientsViewModel";


export default class AllContainersAtClientsList extends BaseVMComponent<{}, {}, any, AllContainersAtClientsViewModel> {

  protected initViewModel() {
    this.viewModel = new AllContainersAtClientsViewModel()
  }

  private getTableColumns(): TableColumn[] {
    const nameCol: TableColumn = {
      title: "Gebindename",
      valueKey: "containerName",
    }
    const quantityCol: TableColumn = {
      title: "Anzahl",
      valueKey: "quantity",
    }

    return [
      nameCol,
      quantityCol,
    ]
  }

  render() {
    return (
      <>
        <TableComponent
          title={"Liste aller Gebinde bei Kunden"}
          columns={this.getTableColumns()}
          tableData={this.viewModel.allContainersAtClients}
          isLoading={this.viewModel.isLoading()}
          offset={0}
          limit={0}
          totalCount={5}
          onPageClick={() => {}}
          showSimpleTable={true}
          cellStyle={{
            fontWeight: (rowNumber, columnNumber) => {
              if (rowNumber === this.viewModel.allContainersAtClients.length - 1) {
                // is sum row
               return "bold"
              }
              return null
            }
          }}
        />
      </>
    );
  }
}