import BaseVMComponent from "../BaseVMComponent";
import React, {FormEvent} from "react";
import Modal from "react-bootstrap/Modal";
import {Button, ButtonGroup} from "react-bootstrap";
import {CreateProductRequestData} from "../../Services/APIServiceInterfaces/Product";
import ContainerModalViewModel from "../../ViewModels/Containers/ContainerModalViewModel";
import ContainerForm from "./ContainerForm";
import PlatformService from "../../Services/PlatformService";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";
import BaseModal from "../BaseModal/BaseModal";


interface Props {
  title: JSX.Element,
  orderData: Order|null,
  onModalClose: () => void,
  onSuccessfullySaved: (closeAfterSave: boolean) => void,
  sendSaveRequest: (data: CreateProductRequestData) => Promise<any>,
  inputDisabled: boolean,
}
interface State {
}
export default class ContainerModal extends BaseVMComponent<Props, State, any, ContainerModalViewModel> {
  static defaultProps = {
    orderData: null,
    inputDisabled: false,
  }

  constructor(props: any) {
    super(props);
  }

  protected initViewModel(): void {
    this.viewModel = new ContainerModalViewModel()
  }

  private closeModal() {
    this.props.onModalClose()
  }

  private onModalCloseClick() {
    this.closeModal()
  }

  private onSuccessfullySaved(closeAfterSave: boolean) {
    this.props.onSuccessfullySaved(closeAfterSave)

    if (closeAfterSave) {
      this.closeModal()
    }
  }

  private showSaveAndCloseButton(): boolean {
    const ps = new PlatformService()
    return ps.supportsSubmitEvent();
  }

  render() {
    return (
      <BaseModal
        title={this.props.title}
        onClose={this.closeModal.bind(this)}
      >
        <ContainerForm
          orderData={this.props.orderData}
          onSuccessfullySaved={(closeAfterSave: boolean) => {
            this.onSuccessfullySaved(closeAfterSave)
          }}
          sendSaveRequest={(data) => {
            return this.props.sendSaveRequest(data)
          }}
          inputDisabled={this.props.inputDisabled}
          buttons={
            <div className={"d-flex mt-4 justify-content-between"} style={{ columnGap: 10 }}>
              <Button
                variant="secondary"
                onClick={this.onModalCloseClick.bind(this)}
              >
                Schließen
              </Button>
              <div className={"d-flex justify-content-end"} style={{ columnGap: 10 }}>
                { !this.props.inputDisabled &&
                  <>
                    <Button
                      variant="primary"
                      type={"submit"}
                    >
                      Speichern
                    </Button>
                    { this.showSaveAndCloseButton() &&
                      <Button
                        variant="primary"
                        type={"submit"}
                        data-save-and-close={true}
                      >
                        Speichern und Schließen
                      </Button>
                    }
                  </>
                }
              </div>
            </div>
          }
        />
      </BaseModal>
    );
  }
}