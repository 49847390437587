import {gql} from "graphql-request";
import {ContainersAtClientDoc} from "./ContainersAtClientGQLDocs";

export const updateClientDoc = () => {
  return gql`
    mutation updateClient(
      $id: ID!
      $companyName: String
      $firstName: String
      $lastName: String
      $leadingTitle: String
      $trailingTitle: String
      $phone: String
      $mail: String
      $isPrivateCustomer: Boolean
      
      $street: String
      $location: String
      $postalCode: String
      
      $notes: String
    ) {
      updateClient(
        id: $id
        companyName: $companyName
        firstName: $firstName
        lastName: $lastName
        leadingTitle: $leadingTitle
        trailingTitle: $trailingTitle
        phone: $phone
        mail: $mail
        isPrivateCustomer: $isPrivateCustomer
        
        street: $street
        location: $location
        postalCode: $postalCode
        
        notes: $notes
      ) {
        id
        firstName
        lastName
        fullName
      }
    }  
  `;
};

export const createClientDoc = () => {
  return gql`
    mutation createClient(
      $companyName: String
      $firstName: String
      $lastName: String
      $leadingTitle: String
      $trailingTitle: String
      $phone: String
      $mail: String
      $isPrivateCustomer: Boolean!
      
      $street: String!
      $location: String!
      $postalCode: String!
      
      $notes: String
    ) {
      createClient(
        companyName: $companyName
        firstName: $firstName
        lastName: $lastName
        leadingTitle: $leadingTitle
        trailingTitle: $trailingTitle
        phone: $phone
        mail: $mail
        isPrivateCustomer: $isPrivateCustomer
        
        street: $street
        location: $location
        postalCode: $postalCode
        
        notes: $notes
      ) {
        id
        firstName
        lastName
        fullName
      }
    }  
  `;
};

export const clientsDoc = (options: {withAddress?: boolean}) => {
  let clientFields = `
      id
      companyName
      firstName
      lastName
      fullName
      notes
      archived
      
      containersAtClient {
        ${ContainersAtClientDoc()}
      }
      
      lastContainersAtClientSigned {
        ${ContainersAtClientDoc()}
      }
      
      lastOrderOfClient {
        id
        date
        orderProducts {
          id
          productId
          productQuantity
        }
        orderContainers {
          id
          containerId
          containerQuantity
        }
      }
    `
  if (options.withAddress === true) {
    clientFields += `
      address {
        id
        street
        location
        postalCode
        addressAsString
      }
    `
  }

  return gql`
    query clients(
      $offset: Int!, 
      $limit: Int!,
      $name: String,
      $location: String,
      $postalCode: String,
      $street: String,
      $lastOrderFrom: Date,
      $lastOrderTo: Date,
      $lastContainerConfirmationFrom: Date,
      $lastContainerConfirmationTo: Date,
      $containersAtClientFrom: Int,
      $containersAtClientTo: Int,
      $itemOrder: [ItemOrder],
      $onlyArchived: Boolean,
    ) {
      clients(
        offset: $offset,
        limit: $limit,
        name: $name,
        location: $location,
        postalCode: $postalCode,
        street: $street,
        lastOrderFrom: $lastOrderFrom,
        lastOrderTo: $lastOrderTo,
        lastContainerConfirmationFrom: $lastContainerConfirmationFrom,
        lastContainerConfirmationTo: $lastContainerConfirmationTo,
        containersAtClientFrom: $containersAtClientFrom,
        containersAtClientTo: $containersAtClientTo,
        itemOrder: $itemOrder,
        onlyArchived: $onlyArchived,
      ) {
        clients {
          ${clientFields}
        }
        count
      }
    }     
  `;
};

const clientDetailFields = () => {
  return `
    id
    companyName
    firstName
    lastName
    fullName
    leadingTitle
    trailingTitle
    phone
    mail
    isPrivateCustomer
    notes
    archived
    
    address {
      street
      location
      postalCode
    }
    
    containersAtClient {
      ${ContainersAtClientDoc()}
    }
  `
}

export const clientDetailsDoc = () => {
  return gql`
    query client(
      $id: Int!
    ) {
      client(
        id: $id
      ) {
        ${clientDetailFields()}
      }
    }    
  `;
};


export const archiveClientDoc = () => {
  return gql`
    mutation archiveClient(
      $id: ID!
    ) {
      archiveClient(
        id: $id
      ) {
        ${clientDetailFields()}
      }
    }    
  `;
};

export const unarchiveClientDoc = () => {
  return gql`
    mutation unarchiveClient(
      $id: ID!
    ) {
      unarchiveClient(
        id: $id
      ) {
        ${clientDetailFields()}
      }
    }    
  `;
};



