import BaseVMComponent from "../BaseVMComponent";
import TableComponentViewModel from "../../ViewModels/TableComponentViewModel";
import React, {CSSProperties, Fragment} from "react";
import LoadingComponent from "./LoadingComponent";
import {Property} from "csstype";
import {IItemOrder} from "../../Services/APIServiceInterfaces/IItemOrder";
import {NavLink} from "react-router-dom";


export interface TableColumn {
  title: string
  valueKey: string
  resolveValue?: (tableDataEntry: any) => string
  backgroundColor?: (tableDataEntry: any) => string|null
  onColumnHeaderClick?: () => void
  traitAsDetailClick?: boolean,
}
export const ActionButtonsCol: TableColumn = {
  title: "Aktionen",
  valueKey: "action-buttons"
}
export interface TableCellStyle {
  backgroundColor?: (rowNumber: number, columnNumber: number) => string | null,
  fontWeight?: (rowNumber: number, columnNumber: number) => Property.FontWeight | null,
}

interface Props {
  title?: string,
  columns: TableColumn[],
  cellStyle?: TableCellStyle,
  tableData: any[],
  isLoading: boolean,
  offset?: number,
  limit?: number,
  totalCount: number,
  pageNumber?: number,
  showSimpleTable?: boolean,
  cardStyle?: React.CSSProperties | null,
  cardBodyStyle?: React.CSSProperties | null,
  hideTableHeading?: boolean,
  onPageClick?: (page: number) => void,
  pageLocation?: string,
  detailsLocation?: string,
  leftButtons?: JSX.Element,
  itemOrder?: IItemOrder,
}
interface State {
}
export default class TableComponent extends BaseVMComponent<Props, State, any, TableComponentViewModel> {

  constructor(props: Props) {
    super(props);
  }

  protected initViewModel(): void {
    this.viewModel = new TableComponentViewModel()
  }

  private getRowHeading(): JSX.Element | null {
    if (this.props.hideTableHeading) {
      return null
    }

    return (
      <thead>
        <tr>
          { this.props.columns.map(column => {
            let classes: string[] = []
            let arrowElement: JSX.Element | null = null
            if (!this.props.showSimpleTable && column.onColumnHeaderClick != null) {
              // order changing possible
              classes.push('clickable')

              if (this.props.itemOrder != null && this.props.itemOrder.colName === column.valueKey) {
                if (this.props.itemOrder.ascOrDesc === "asc") {
                  arrowElement = (
                    <span className={"ml-1"}>
                      ↓
                    </span>
                  )
                } else {
                  arrowElement = (
                    <span className={"ml-1"}>
                      ↑
                    </span>
                  )
                }
              }
            }

            return (
              <th
                key={column.valueKey}
                className={classes.join(" ")}
                onClick={() => {
                  if (column.onColumnHeaderClick != null) {
                    column.onColumnHeaderClick()
                  }
                }}
              >
                <span>
                  {column.title}
                </span>

                {arrowElement}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  private getRowDataRows(): JSX.Element[] {
    let rows: JSX.Element[] = []

    for (const tableDataEntry of this.props.tableData) {
      const rowIndex = this.props.tableData.indexOf(tableDataEntry);
      rows.push(
        <tr key={rowIndex}>
          {this.getRowSingleDataRow(rowIndex, tableDataEntry)}
        </tr>
      )
    }

    if (rows.length === 0) {
      rows.push(
        <tr>
          <td colSpan={this.props.columns.length} style={{ textAlign: "center", opacity: 0.66 }}>
            keine Einträge
          </td>
        </tr>
      )
    }

    return rows
  }

  private getRowSingleDataRow(rowIndex: number, tableDataEntry: any): JSX.Element[] {
    let tableDataElements: JSX.Element[] = []

    for (const column of this.props.columns) {
      const colIndex = this.props.columns.indexOf(column);

      if (column === ActionButtonsCol && this.props.detailsLocation) {
        // action buttons
        tableDataElements.push(
          <td key={"td-" + colIndex} className={"action-buttons"}>
            <NavLink
              to={this.props.detailsLocation.replace("{id}", tableDataEntry.id.toString())}
            >
              <button
                type={"button"}
                className={"btn btn-primary"}
              >
                Details
              </button>
            </NavLink>
          </td>
        )
      } else {
        // data rows
        let value = tableDataEntry[column.valueKey]
        if (column.resolveValue != null) {
          value = column.resolveValue(tableDataEntry)
        }

        // custom style
        let style: any = {}
        // style from column
        if (column.backgroundColor != null) {
          const backgroundColor = column.backgroundColor(tableDataEntry)
          if (backgroundColor != null) {
            style.backgroundColor = backgroundColor
          }
        }
        // style from cell
        let backgroundColor = this.props.cellStyle?.backgroundColor != null ? this.props.cellStyle?.backgroundColor(rowIndex, colIndex) : null
        let fontWeight = this.props.cellStyle?.fontWeight != null ? this.props.cellStyle?.fontWeight(rowIndex, colIndex) : null
        if (backgroundColor != null) {
          style.backgroundColor = backgroundColor
        }
        if (fontWeight != null) {
          style.fontWeight = fontWeight
        }

        tableDataElements.push(
          <td
            key={"td-" + colIndex}
            style={style}
          >
            {value}
          </td>
        )
      }
    }

    return tableDataElements
  }

  private getPageButtons() {
    let pageNumber = this.props.pageNumber || 1
    let numPages = this.viewModel.getNumberOfPages(
      this.props.limit!,
      this.props.totalCount
    )

    let pageButtons: JSX.Element[] = []

    // previous page button
    let previousBtnClass = "paginate_button page-item previous"
    let previousBtnDisabled = false
    if (pageNumber === 1) {
      previousBtnClass += " disabled"
      previousBtnDisabled = true
    }

    pageButtons.push(
      <li
        className={previousBtnClass}
        key={"prev"}
        onClick={() => this.onPageClick(pageNumber - 1, previousBtnDisabled)}
      >
        <NavLink
          to={this.props.pageLocation?.replace("{page}", (pageNumber - 1).toString()) || ""}
          aria-disabled={previousBtnDisabled}
          className="page-link"
        >
          Zurück
        </NavLink>
      </li>
    )

    // page numbers
    let firstPageNum: number;
    if (pageNumber != null) {
      firstPageNum = pageNumber - 1
      console.log("debug", "firstPageNum", firstPageNum)
      console.log("debug", "pageNumber", pageNumber)
      if (pageNumber === numPages) {
        firstPageNum = numPages - 2
      }
      firstPageNum = Math.max(1, firstPageNum);
    } else {
      firstPageNum = 1;
    }
    if (pageNumber != null && 3 <= pageNumber) {
      pageButtons.push(
        <li
          className={"paginate_button page-item"}
          key={"before"}
        >
            <span
              className="page-link"
              aria-disabled={true}
            >
              ...
            </span>
        </li>
      )
    }

    for (let i = firstPageNum; i <= numPages; i++) {
      if (i > firstPageNum + 2) {
        break
      }

      let className = "paginate_button page-item"
      if (i === pageNumber) {
        className += " active"
      }

      pageButtons.push(
        <li
          className={className}
          key={i}
          onClick={() => this.onPageClick(i)}
        >
          <NavLink
            to={this.props.pageLocation?.replace("{page}", (i).toString()) || ""}
            className="page-link"
          >
            {i}
          </NavLink>
        </li>
      )
    }

    console.log("debug", "numPages", numPages)
    if (pageNumber <= numPages - 2) {
      // appended points
      pageButtons.push(
        <li
          className={"paginate_button page-item"}
        >
            <span
              className="page-link"
            >
              ...
            </span>
        </li>
      )
    }

    // next page button
    let nextBtnClass = "paginate_button page-item previous"
    let nextBtnDisabled = false
    if ((pageNumber || 1) >= numPages) {
      nextBtnClass += " disabled"
      nextBtnDisabled = true
    }
    pageButtons.push(
      <li
        className={nextBtnClass}
        key={"next"}
        onClick={() => this.onPageClick(pageNumber + 1, nextBtnDisabled)}
      >
        <NavLink
          to={this.props.pageLocation?.replace("{page}", (pageNumber + 1).toString()) || ""}
          aria-disabled={nextBtnDisabled}
          className="page-link"
        >
          Weiter
        </NavLink>
      </li>
    )

    return pageButtons
  }

  private onPageClick(page: number, disabled: boolean = false) {
    if (disabled) {
      return
    }
    this.props.onPageClick?.(page)
  }

  render() {
    let cardStyle: CSSProperties = {}
    let cardBodyStyle: CSSProperties = {}
    if (this.props.cardStyle == null) {
      if (this.props.showSimpleTable) {
        cardStyle = {
          boxShadow: "unset"
        }
      }
    } else {
      cardStyle = this.props.cardStyle
    }
    if (this.props.cardBodyStyle == null) {
      if (this.props.showSimpleTable) {
        cardBodyStyle.padding = 0
        cardStyle.marginTop = "1rem"
      }
    } else {
      cardBodyStyle = this.props.cardBodyStyle
    }

    return (
      <div
        className="card responsive-table"
        style={cardStyle}
      >
        {!this.props.showSimpleTable &&
          <div className="card-header">
            <h4 className="card-title">{this.props.title}</h4>
          </div>
        }
        <div
          className="card-body"
          style={cardBodyStyle}
        >
          <LoadingComponent
            shown={this.props.isLoading}
          />

          { !this.props.isLoading &&
            <Fragment>
              <div
                style={{
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                <table
                  className="table table-bordered table-striped"
                  style={{
                    width: "100%",
                    overflowX: "auto",
                  }}
                >
                  { this.getRowHeading() }
                  <tbody>
                  { this.getRowDataRows() }
                  </tbody>
                </table>
              </div>

              { !this.props.showSimpleTable &&
                <div className={"table-footer"}>
                  <div className="">
                    {this.props.offset! + 1} bis {this.props.offset! + this.props.limit!} angezeigt ({this.props.totalCount} insgesamt)
                  </div>

                  <div className="row mt-1">
                    <div className="col-sm-12 col-md-6">
                      { this.props.leftButtons &&
                        this.props.leftButtons
                      }
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="d-flex justify-content-end"
                        id="example1_paginate"
                      >
                        <ul className="pagination">
                          { this.getPageButtons() }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Fragment>
          }
        </div>
      </div>
    )
  }
}


