import BaseVMComponent from "../BaseVMComponent";
import TableComponent, {ActionButtonsCol, TableColumn} from "../Shared/TableComponent";
import {useNavigate, useParams} from "react-router-dom";
import FloatService from "../../Services/FloatService";
import ContainersTableComponentViewModel from "../../ViewModels/Containers/ContainersTableComponentViewModel";
import Container from "../../Model/Container";


interface Props {
  navigation: any,
  page?: number,
  showSimpleTable: boolean,
  rowLimit: number|null,
}
interface State {
  loading: boolean,
}
class _ContainersTableComponent extends BaseVMComponent<Props, State, any, ContainersTableComponentViewModel> {
  static defaultProps = {
    showSimpleTable: false,
    rowLimit: null,
  }

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ContainersTableComponentViewModel(this.props.rowLimit)
  }

  async componentDidMount() {
    this.viewModel.setPage(this.props.page!)
    this.viewModel.loadContainers()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.page !== this.props.page) {
      this.viewModel.setPage(this.props.page!)
      this.viewModel.loadContainers()
    }
  }

  async reloadOrders() {
    return this.viewModel.loadContainers()
  }

  private onPageChange(page: number) {
    this.props.navigation("/admin/orders/" + page)
  }

  private getTableColumns(): TableColumn[] {
    const idCol: TableColumn = {
      title: "ID",
      valueKey: "id",
    }
    const nameCol: TableColumn = {
      title: "Name",
      valueKey: "name",
    }
    const basePriceCol: TableColumn = {
      title: "Bruttopreis",
      valueKey: "bruttoPrice",
      resolveValue: (tableDataEntry: Container) => {
        return FloatService.toGermanCurrencyValueString(tableDataEntry.bruttoPrice)
      }
    }

    if (this.props.showSimpleTable) {
      return [
        nameCol,
        basePriceCol,
      ]
    }

    return [
      idCol,
      nameCol,
      basePriceCol,
    ]
  }

  render() {
    return (
      <>
        <TableComponent
          title={"Liste aller Gebindearten"}
          columns={this.getTableColumns()}
          tableData={this.viewModel.containers}
          isLoading={this.viewModel.loading}
          offset={this.viewModel.getRequestOffset()}
          limit={this.viewModel.getRequestLimit()}
          totalCount={this.viewModel.allOrdersCount}
          pageNumber={this.props.page}
          onPageClick={this.onPageChange.bind(this)}
          showSimpleTable={this.props.showSimpleTable}
        />
      </>
    )
  }
}


const ProductsTableComponent: any = (props: Props) => {
  const navigation = useNavigate();
  const { page } = useParams();

  return (
    <_ContainersTableComponent
      {...props}
      navigation={navigation}
      page={parseInt(page || "1")}
      ref={(props as any).innerRef}
    />
  )
}


export default ProductsTableComponent;