import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import RouterWrapper from "./RouterWrapper";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import "./service-worker-register.js";
import "./scss/styles.scss";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// API Calls / GraphQL
const queryClient = new QueryClient();

// Sentry
const sentryEnabled = !window.location.href.includes("localhost")

Sentry.init({
  dsn: "https://e641d44caafd485d8a90db28be4caa6e@o1336515.ingest.sentry.io/4505039274442752",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: sentryEnabled,
});


root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RouterWrapper />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
